@import "../../../styles/Variables.scss";

.top-nav {
  border-bottom: 1px solid #ececec;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: 60px;
  .topnav-header {
    .dropdown.nav-item {
      > a {
        font-size: 12px;
        padding: 0;
        color: $gray-600;
      }
    }

    #header-portal {
      display: flex;
      font-size: 20px;
    }
  }
}
