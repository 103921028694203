@import "./Variables.scss";

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button.btn-link {
  // margin: 0px;
  padding: 0px;
  border: none;
  background:transparent;
  &:hover {
    background:transparent;
  }
}

bg-white {
  background-color: $white;
}

.form-group.required .form-label:after {
  content:"*";
  color: $error;
}
.form-group {
  .invalid-feedback {
    display: block;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.table {
  thead {
    th {
      border-top: none;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.layout-tabs {
  &.nav-tabs {
    width: 100%;
    height: 51px;
    border: 0;
  }
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-600;
    border: 0;
    min-width: 100px;
    border-radius: 0;
  }
}

.sub-header-container {
  display: flex;
  top: 60px;
  left: 0px;
  right: 0px;
  z-index: 10;
  position: sticky;
  background-color: $gray-100;

  .nav-tabs {
    width: 100%;
  }

  .nav-link {
    padding-bottom: 15px;
    padding-top: 15px;
    color: $black;
  }

  .nav-tabs .nav-link,
  .nav-tabs .nav-item.dropdown {
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: $gray-100;

    &.active {
      background-color: $gray-100;
      font-weight: bold;
      border-bottom: 3px solid $px-bright-blue !important;
      border-top: none;
      border-left: none;
      border-right: none;
    }

    &:hover {
      border-color: transparent;
    }
  }

  .nav-tabs .nav-item.dropdown {
    margin-bottom: 0px;

    > .nav-link {
      border: none;
    }

    &.show {
      > .nav-link {
        background-color: transparent;
      }
    }

    &.active {
      > .nav-link {
        color: $gray-700;
      }
    }
  }
}

.tab-pane {
  > .card {
    border-top: none;
    border-radius: 0px;
  }
}

// fieldset {
//   // border-bottom: 1px solid #E3E3E3 !important;
// }

h5 {
  font-weight: bold !important;
}

.hoverable {
  .show-on-hover,
  .show-inline-on-hover {
    display: none;
  }

  .visible-on-hover {
    visibility: hidden;
  }

  .hide-on-hover {
    display: block;
  }

  &:hover,
  &:focus {
    .show-on-hover {
      display: block;
    }

    .show-inline-on-hover {
      display: inline-block;
    }

    .hide-on-hover {
      display: none;
    }

    .visible-on-hover {
      visibility: visible;
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
  padding-right: 0.75rem;
}

/* Remove number arrows from Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove number arrows from Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.text-align-center {
  text-align: center;
}
