@import "../../../styles/Variables.scss";

.side-bar {
  .nav {
    .nav-link {
      color: $white;
      &.active {
        background-color: $px-bright-blue;
      }
    }
  }
}
